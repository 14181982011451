<template>
  <div class="about-gallery" :class="pending ? '_loading' : null">
    <h3 class="about-gallery__title">{{ gallery.name }}</h3>
    <CoolLightBox
        :items="galleryImages"
        :srcName="'image_m'"
        :gallery="false"
        :index="imageIndex"
        @close="imageIndex = null">
    </CoolLightBox>
    <div class="about-gallery__container">
      <div class="about-gallery__slider" ref="aboutSlider">
        <BasePreloader v-if="pending"/>
        <div class="about-gallery__wrapper">
          <div
              v-for="(item, index) in galleryImages"
              :key="index"
              class="about-gallery__slide">
            <div class="about-gallery__slide-inner" @click="imageIndex = index">
              <picture class="about-gallery__frame">
                <img :src="$imgPlaceholder"
                     :data-src="item.image_s"
                     class="lazyload about-gallery__image"
                     :alt="gallery.name">
              </picture>
              <div class="about-gallery__overlay">
                <BaseSvg name="ico-plus" width="2rem" height="2rem" class="about-gallery__icon"/>
              </div>
            </div>
          </div>
        </div>
        <div class="about-gallery__navigation">
          <button ref="slide-prev" type="button" class="about-gallery__nav-btn base-btn _nav _prev">
            <BaseSvg name="ico-arrow-right"/>
          </button>
          <button ref="slide-next" type="button" class="about-gallery__nav-btn base-btn _nav _next">
            <BaseSvg name="ico-arrow-right"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Swiper, {Navigation} from 'swiper/core'
  import CoolLightBox from 'vue-cool-lightbox'

  Swiper.use([Navigation])

  export default {
    props: {
      gallery: {
        type: Object,
        default: () => ({})
      },
      pending: {
        type: Boolean,
        default: false
      }
    },
    components: {
      CoolLightBox
    },
    data() {
      return {
        imageIndex: null,
        swiper: null
      }
    },
    computed: {
      galleryImages() {
        return this.gallery && this.gallery.images || []
      }
    },
    methods: {
      createSwiper() {
        this.swiper = new Swiper(this.$refs['aboutSlider'], {
          wrapperClass: 'about-gallery__wrapper',
          slideClass: 'about-gallery__slide',
          slidesPerView: 'auto',
          watchOverflow: true,
          roundLengths: true,
          observer: true,
          speed: 600,
          navigation: {
            prevEl: this.$refs['slide-prev'],
            nextEl: this.$refs['slide-next'],
            disabledClass: '_disabled',
            hiddenClass: '_hidden',
            lockClass: '_hidden'
          }
        })
      }
    },
    mounted() {
      this.createSwiper()
    },
    beforeDestroy() {
      if (this.swiper) {
        setTimeout(() => {
          this.swiper.destroy()
        }, 1000)
      }
    }
  }
</script>
