<template>
  <main class="about-us" :class="{_loading: pending}">
    <BasePreloader v-if="pending"/>
    <div class="about-us__wrapper container">
      <div class="about-us__inner container-inner">
        <h1 class="about-us__title" v-if="title">{{ title }}</h1>
        <div class="about-us__text-content" :class="{'_loading': pending}">
          <div class="about-us__text-content-inner">
            <div
                class="about-us__text-column text"
                v-if="description1"
                v-html="description1"></div>
            <div
                class="about-us__text-column text"
                v-if="description2"
                v-html="description2"></div>
          </div>
        </div>
        <AboutGallery :gallery="gallery" class="about-us__gallery"/>
        <template v-if="socials && socials.length">
          <h4 class="about-us__social-title">{{ $t('Ми в соцмережах') }}</h4>
          <div class="about-us__social social-list" >
            <a
                v-for="social in socials"
                :key="social.key"
                :href="social.link"
                target="_blank"
                :title="social.key"
            >
              <BaseSvg :name="social.iconName"
                       width="2.5rem"
                       height="2.5rem"
                       class="social-list__icon"
              />
            </a>
          </div>
        </template>

        <template v-if="settings.contacts.phone || settings.contacts.email">
          <h4 class="about-us__address-title">{{ $t('Наші контакти') }}:</h4>
          <div class="about-us__address">
            <template v-if="settings.contacts.phone">
              <BaseSvg
                  name="ico-phone"
                  width="3rem"
                  height="3rem"
                  class="about-us__phone-icon"/>
              <a :href="`tel:${settings.contacts.phone}`" style="margin: 0 15px 0 10px">{{ settings.contacts.phone }}</a>
            </template>

            <template v-if="settings.contacts.email">
              <BaseSvg
                  name="ico-email"
                  width="3rem"
                  height="3rem"
                  class="about-us__email-icon"/>
              <a :href="`mailto:${settings.contacts.email}`" style="margin: 0 0 0 10px">{{ settings.contacts.email }}</a>
            </template>
          </div>
        </template>

        <MobApps class="about-us__apps"/>
      </div>
    </div>
  </main>
</template>

<script>
  import AboutGallery from '../components/AboutGallery'
  import getSocials from '../helpers/getSocials'
  import {mapState} from 'vuex'
  import MobApps from '@/components/MobApps'

  export default {
    components: {
      MobApps,
      AboutGallery
    },
    data() {
      return {
        banner: {},
        gallery: {},
        imageIndex: null,
        description1: '',
        description2: '',
        socials: [],
        pending: true,
        title: null
      }
    },
    computed: {
      ...mapState('settings', ['settings'])
    },
    methods: {
      load() {
        this.pending = true
        this.$store.dispatch('aboutUs/load').then((data) => {
          this.banner = data.banner
          this.gallery = data.gallery
          this.description1 = data.description_first
          this.description2 = data.description_second
          this.title = data.title
          this.socials = getSocials(data)
        }).finally(() => {
          this.pending = false
        })
      }
    },
    created() {
      this.load()
    }
  }
</script>
